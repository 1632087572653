<template>
  <tp-modal
    name="modal-browsing-services"
    width="70%"
    max-width="640px"
    persistent
  >
    <v-form ref="browsingServicesForm">
      <v-card flat>
        <!-- Start: Title and actions -->
        <v-card-title class="pb-4">
          <div class="font-weight-bold" v-if="browsingService.id">
            Phiếu hiệu suất #{{ browsingService.id }}
          </div>
          <div class="font-weight-bold" v-else>
            Phiếu hiệu suất
          </div>

          <v-spacer></v-spacer>

          <!-- <v-btn
            color="red lighten-5 red&#45;&#45;text rounded-lg text&#45;&#45;accent-2 mr-2"
            depressed
            @click="closeModal"
            v-if="!browsingService.id"
          >
            Hủy
          </v-btn> -->

          <v-btn
            class="cyan lighten-5 rounded-lg mr-2"
            color="primary"
            text
            v-if="!browsingService.id"
            @click="createBrowserService"
          >
            Tạo
          </v-btn>
          <v-btn
            class="cyan lighten-5 rounded-lg mr-2"
            color="primary"
            text
            v-else
            @click="updateBrowser"
          >
            Lưu
          </v-btn>
          <v-btn icon @click="closeModal()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <!-- End: Title and actions -->
        <!-- Start: Main content -->
        <v-container class="grey lighten-3 pa-5" fluid>
          <v-card flat class="px-5 py-5">
            <v-row>
              <v-col class="py-0" cols="12">
                <div class="font-weight-bold mb-2">Nhân sự</div>
                <v-autocomplete
                  v-if="browsingService.id"
                  v-model="browsingService.user_id"
                  class="tp-filter-autocomplete"
                  :items="employees"
                  dense
                  flat
                  solo
                  clearable
                  deletable-chips
                  disabled
                  hide-details
                  hide-selected
                  item-text="name"
                  item-value="id"
                  no-data-text="Không có dữ liệu"
                  outlined
                  label="Outlined"
                  placeholder="Chọn nhân sự"
                ></v-autocomplete>

                <v-autocomplete
                  v-else
                  v-model="browsingService.user_id"
                  class="tp-filter-autocomplete"
                  :items="employees"
                  dense
                  flat
                  solo
                  clearable
                  hide-details
                  hide-selected
                  item-text="name"
                  item-value="id"
                  no-data-text="Không có dữ liệu"
                  outlined
                  label="Chọn nhân sự"
                  placeholder="Chọn nhân sự"
                  :rules="[value => !!value || 'Không được bỏ trống']"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="mt-5">
              <v-col class="py-0" cols="6">
                <div class="font-weight-bold mb-2">Trạng thái</div>
                <v-select
                  v-model="browsingService.status"
                  class="text-body-1"
                  dense
                  hide-details
                  hide-selected
                  :menu-props="{ nudgeTop: '-40px' }"
                  item-text="text"
                  item-value="value"
                  :items="performStatusItems"
                  outlined
                  placeholder="Chọn trạng thái"
                ></v-select>
              </v-col>
              <v-col class="py-0" cols="6">
                <div class="font-weight-bold mb-2">Loại dịch vụ</div>
                <v-select
                  v-model="browsingService.role"
                  class="text-body-1"
                  dense
                  hide-details
                  hide-selected
                  :menu-props="{ nudgeTop: '-40px' }"
                  item-text="text"
                  item-value="value"
                  :items="rolesName"
                  outlined
                  placeholder="Chọn loại"
                  @change="changeRole(browsingService.role)"
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-if="!browsingService.id" class="mt-5">
              <v-col class="py-0" cols="6">
                <div class="font-weight-bold mb-2">Loại phiếu</div>
                <v-select
                  v-model="browsingService.model_name"
                  class="text-body-1"
                  dense
                  hide-details
                  hide-selected
                  :menu-props="{ nudgeTop: '-40px' }"
                  item-text="text"
                  item-value="value"
                  :items="listModel"
                  outlined
                  placeholder="Chọn model"
                  :rules="[value => !!value || 'Không được bỏ trống']"
                  @change="changeModel(browsingService.model_name)"
                ></v-select>
              </v-col>
              <v-col class="py-0" cols="6">
                <div class="font-weight-bold mb-2">Mã phiếu</div>
                <v-autocomplete
                  v-model="browsingService.model_id"
                  class="tp-filter-autocomplete"
                  :items="listModelId"
                  dense
                  flat
                  solo
                  clearable
                  hide-details
                  hide-selected
                  item-text="code"
                  item-value="id"
                  no-data-text="Không có dữ liệu"
                  outlined
                  label="Chọn mã phiếu"
                  placeholder="Chọn mã phiếu"
                  :rules="[value => !!value || 'Không được bỏ trống']"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="mt-5" v-if="browsingService.role === 'SHIPPING'">
              <v-col cols="6" class="py-0">
                <div class="font-weight-bold mb-2">
                  Số km
                </div>
                <v-text-field
                  v-model="browsingService.value"
                  class="text-body-1"
                  clearable
                  dense
                  hide-details="auto"
                  outlined
                  placeholder="Số km"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-5">
              <v-col class="py-0" cols="12">
                <div class="font-weight-bold mb-2">Ghi chú</div>
                <v-textarea
                  v-model="browsingService.note"
                  class="text-body-1"
                  background-color="white"
                  dense
                  filled
                  outlined
                  placeholder="Ghi chú"
                  single-line
                  required
                  rows="2"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-divider></v-divider>

            <v-row class="mt-5" v-if="browsingService.id">
              <v-col class="py-0" cols="12">
                <div class="font-weight-bold mb-2">Chứng từ</div>
              </v-col>
              <v-col class="py-0" cols="12">
                <div class="font-weight-bold d-flex align-center">
                  <span class="mr-2">{{ browsingService.model_code }}</span>
                  <v-chip
                    v-if="
                      getValueModelStatus(
                        browsingService.model_status,
                        listStatusBill
                      ).length > 0 &&
                        browsingService.model_name === 'App\\Models\\HoaDonBan'
                    "
                    class="font-weight-bold"
                    :color="
                      browsingService.model_status === 1
                        ? 'green'
                        : browsingService.model_status === -1
                        ? 'red accent-2'
                        : 'blue accent-2'
                    "
                    small
                    outlined
                  >
                    {{
                      getValueModelStatus(
                        browsingService.model_status,
                        listStatusBill
                      )
                    }}
                  </v-chip>
                  <v-chip
                    v-if="
                      getValueModelStatus(
                        browsingService.model_status,
                        listStatusOrder
                      ).length > 0 &&
                        browsingService.model_name ===
                          'App\\Models\\OrderHandle'
                    "
                    class="font-weight-bold"
                    :color="
                      browsingService.model_status === 6
                        ? 'green'
                        : browsingService.model_status === -1
                        ? 'red accent-2'
                        : 'blue accent-2'
                    "
                    small
                    outlined
                  >
                    {{
                      getValueModelStatus(
                        browsingService.model_status,
                        listStatusOrder
                      )
                    }}
                  </v-chip>
                  <v-chip
                    v-if="
                      getValueModelStatus(
                        browsingService.model_status,
                        listStatusBHSC
                      ).length > 0 &&
                        browsingService.model_name === 'App\\Models\\BHSC'
                    "
                    class="font-weight-bold"
                    :color="
                      browsingService.model_status === 1
                        ? 'green'
                        : browsingService.model_status === -1
                        ? 'red accent-2'
                        : 'blue accent-2'
                    "
                    small
                    outlined
                  >
                    {{
                      getValueModelStatus(
                        browsingService.model_status,
                        listStatusBHSC
                      )
                    }}
                  </v-chip>
                  <v-chip
                    v-if="
                      getValueModelStatus(
                        browsingService.model_status,
                        listStatusReturn
                      ).length > 0 &&
                        browsingService.model_name ===
                          'App\\Models\\KhachTraHang'
                    "
                    class="font-weight-bold"
                    :color="
                      browsingService.model_status === 1
                        ? 'green'
                        : browsingService.model_status === -1
                        ? 'red accent-2'
                        : 'blue accent-2'
                    "
                    small
                    outlined
                  >
                    {{
                      getValueModelStatus(
                        browsingService.model_status,
                        listStatusReturn
                      )
                    }}
                  </v-chip>
                  <v-chip
                    v-if="
                      getValueModelStatus(
                        browsingService.model_status,
                        listStatusChuyenHang
                      ).length > 0 &&
                        browsingService.model_name === 'App\\Models\\ChuyenHang'
                    "
                    class="font-weight-bold"
                    :color="
                      browsingService.model_status === 1
                        ? 'green'
                        : browsingService.model_status === -1
                        ? 'red accent-2'
                        : 'blue accent-2'
                    "
                    small
                    outlined
                  >
                    {{
                      getValueModelStatus(
                        browsingService.model_status,
                        listStatusChuyenHang
                      )
                    }}
                  </v-chip>
                </div>
              </v-col>
            </v-row>
            <v-row class="mt-5">
              <v-col class="py-0" cols="12">
                <div class="d-flex flex-row align-center justify-space-between">
                  <div>Ảnh đính kèm</div>
                  <v-btn
                    class="cyan lighten-5 primary--text text-body-2 font-weight-bold text-none rounded"
                    depressed
                    small
                    @click="$refs.fileDocs.click()"
                  >
                    <v-icon class="mr-1" left>mdi-upload-outline</v-icon>
                    Tải lên
                  </v-btn>
                  <input
                    type="file"
                    hidden
                    ref="fileDocs"
                    accept=".png, .jpg"
                    @change="selectFile()"
                  />
                </div>
              </v-col>
              <v-col
                cols="12"
                v-if="browsingService.docs && browsingService.docs.length > 0"
              >
                <div class="d-flex align-center">
                  <div
                    v-for="(item, index) in browsingService.docs"
                    :key="`image-${item}`"
                    class="image"
                  >
                    <a
                      class="d-block"
                      :href="baseFileUrl + '/' + item"
                      target="_blank"
                      :style="
                        `background-image: url('` +
                          baseFileUrl +
                          '/' +
                          item +
                          '?w=70&h=70' +
                          `')`
                      "
                    ></a>
                    <v-btn
                      class="icon-remove-image"
                      x-small
                      icon
                      @click="browsingService.docs.splice(index, 1)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-col>
              <v-col
                cols="12"
                v-if="
                  !browsingService.docs ||
                    (browsingService.docs && browsingService.docs.length === 0)
                "
              >
                Không có ảnh đính kèm
              </v-col>
            </v-row>
          </v-card>
        </v-container>
        <!-- End: Main content -->
      </v-card>
    </v-form>
  </tp-modal>
</template>

<script>
import { mapGetters } from "vuex";
import BrowsingService from "@/modules/Transaction/service/browsing.service";

export default {
  props: {
    employees: {
      type: Array
    }
  },
  data() {
    return {
      file: null,
      performStatusItems: [
        { value: 1, text: "Đã duyệt" },
        { value: 0, text: "Chờ duyệt" },
        { value: -1, text: "Bị hủy" }
      ],
      serviceGroupItems: [
        { value: "TECHNIQUE", text: "Dịch vụ phần mềm" },
        { value: "TECHNIQUE_HARDWARE", text: "Dịch vụ phần cứng" },
        { value: "PACKAGING", text: "Dịch vụ đóng gói, giao hàng" }
      ],
      listStatusOrder: [
        {
          id: -2,
          value: "Chờ xác nhận"
        },
        {
          id: -1,
          value: "Đã hủy"
        },
        {
          id: 1,
          value: "Đã duyệt"
        },
        {
          id: 2,
          value: "Chờ duyệt"
        },
        {
          id: 3,
          value: "Đã sẵn sàng"
        },
        {
          id: 4,
          value: "Đang chuẩn bị"
        },
        {
          id: 5,
          value: "Đang vận chuyển"
        },
        {
          id: 6,
          value: "Đang hoàn thành"
        }
      ],
      listStatusBill: [
        {
          id: -1,
          value: "Đã hủy"
        },
        {
          id: 1,
          value: "Thành công"
        }
      ],
      listStatusReturn: [
        {
          id: -1,
          value: "Đã hủy"
        },
        {
          id: 1,
          value: "Đã trả"
        }
      ],
      listStatusBHSC: [
        {
          id: -1,
          value: "Đã hủy"
        },
        {
          id: 1,
          value: "Đã tiếp nhận"
        },
        {
          id: 2,
          value: "Đang xử lý"
        },
        {
          id: 3,
          value: "Xử lý xong"
        },
        {
          id: 4,
          value: "Đã trả khách"
        }
      ],
      listStatusChuyenHang: [
        {
          id: -2,
          value: "Chờ xác nhận"
        },
        {
          id: -1,
          value: "Đã hủy"
        },
        {
          id: 1,
          value: "Đã chuyển"
        }
      ],
      listModel: [
        {
          text: "Bảo hành sửa chữa",
          value: "App\\Models\\BHSC"
        },
        {
          text: "Hóa đơn nhập",
          value: "App\\Models\\HoaDonNhap"
        },
        {
          text: "Hóa đơn bán",
          value: "App\\Models\\HoaDonBan"
        },
        {
          text: "Đơn đặt hàng",
          value: "App\\Models\\Order"
        },
        {
          text: "Kho không kinh doanh",
          value: "App\\Models\\OffBiz"
        },
        {
          text: "Chuyển hàng",
          value: "App\\Models\\ChuyenHang"
        },
        {
          text: "Đơn xử lý",
          value: "App\\Models\\OrderHandle"
        }
      ],
      listModelId: []
    };
  },
  computed: {
    ...mapGetters({
      browsingService: "BROWSING/browsingService",
      browsingServicesStatusRequest: "BROWSING/statusRequest",
      docsBrowsingService: "BROWSING/docsBrowsingService",
      rolesName: "ROLE/rolesName"
    })
  },
  methods: {
    closeModal() {
      this.$refs.browsingServicesForm.reset();
      this.$modal.hide({
        name: "modal-browsing-services"
      });
      this.$store.dispatch("BROWSING/clearBrowsingService");
    },

    changeRole(name) {
      if (name !== "PACKAGING") this.browsingService.value = "";
    },

    async changeModel(name) {
      try {
        const response = await BrowsingService.getModelIdFromModelName(name);
        if (response.status === 200 && response.data) {
          this.listModelId = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async createBrowserService() {
      const browsingServicesValid = await this.$refs.browsingServicesForm.validate();
      if (!browsingServicesValid) return;

      await this.$store.dispatch(
        "BROWSING/createBrowsingService",
        this.browsingService
      );

      if (
        this.browsingServicesStatusRequest.value ===
        "success-createBrowsingService"
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Tạo thành công"
          }
        });

        this.$emit("updateList");
        this.closeModal();
      }
      if (
        this.browsingServicesStatusRequest.value ===
        "error-createBrowsingService"
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: this.browsingServicesStatusRequest.message
              ? this.browsingServicesStatusRequest.message
              : ""
          }
        });
      }
    },

    async selectFile() {
      this.file = this.$refs.fileDocs.files;
      this.$emit("change", URL.createObjectURL(this.file[0]));

      const formData = new FormData();

      Array.from(this.file).forEach(f => {
        formData.append("file", f);
        formData.append("folder", "hrm/labor-contracts");
      });

      await this.uploadImage(formData);

      if (this.browsingServicesStatusRequest.value === "success-uploadDocs") {
        if (!this.browsingService.docs) this.browsingService.docs = [];
        this.browsingService.docs.push(this.docsBrowsingService);
      }
      if (this.browsingServicesStatusRequest.value === "error-uploadDocs") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: this.browsingServicesStatusRequest.message
              ? this.browsingServicesStatusRequest.message
              : ""
          }
        });
      }
      // reset ref
      const input = this.$refs.fileDocs;
      input.type = "text";
      input.type = "file";
    },

    async uploadImage(formData) {
      await this.$store.dispatch("BROWSING/uploadDocs", formData);
    },
    async updateBrowser() {
      if (!this.browsingService.id) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Có lỗi xảy ra. Vui lòng tải lại trang!"
          }
        });
      } else {
        // {
        //   id: this.browsingService.id,
        //       note: this.browsingService.note ? this.browsingService.note : "",
        //     user_id: this.browsingService.user_id
        //     ? this.browsingService.user_id
        //     : null,
        //     status: this.browsingService.status,
        //     role: this.browsingService.role ? this.browsingService.role : null,
        //     docs: this.browsingService.docs ? this.browsingService.docs : null,
        //     model_name: this.browsingService.model_name
        //     ? this.browsingService.model_name
        //     : null,
        //     value: ""
        // }
        await this.$store.dispatch(
          "BROWSING/updateBrowser",
          this.browsingService
        );

        // Alert and replace router
        if (
          this.browsingServicesStatusRequest.value === "success-updateBrowser"
        ) {
          // Alert
          this.$toast.show({
            name: "toast-action-alert",
            payload: {
              message: "Cập nhật thành công"
            }
          });
          this.closeModal();
        }
        // Alert and replace router
        if (
          this.browsingServicesStatusRequest.value === "error-updateBrowser"
        ) {
          // Alert
          this.$toast.show({
            name: "toast-action-alert",
            payload: {
              message: this.browsingServicesStatusRequest.message
                ? this.browsingServicesStatusRequest.message
                : ""
            }
          });
        }
      }
    },
    getValueModelStatus(value, listStatus) {
      let text = listStatus.filter(item => item.id === value);
      if (text.length > 0) return text[0].value;
      else return "";
    }
  }
};
</script>

<style lang="scss" scoped>
.tab-item {
  min-height: 500px;
}

.image {
  position: relative;

  a {
    padding-top: 70px;
    width: 70px;
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  &:hover .icon-remove-image {
    opacity: 1;
  }

  .icon-remove-image {
    opacity: 0;
    transition: all ease 0.2s;
    position: absolute;
    top: -5px;
    right: -5px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 10;
    color: #868686;
    background: #ffffff4a;
    border: 1px solid #868686;

    a {
      color: inherit;
      text-decoration: none;
    }
  }
}
</style>
