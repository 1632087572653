<template>
  <div class="d_flex">
    <!-- Start: Filter area -->
    <div class="px-5">
      <filter-area
        :employees="employees"
        :branches-filter="branchesFilter"
        :employees-filter="employeesFilter"
        :date-filter="dateFilter"
        :status-filter="statusFilter"
        :customer-filter="customerFilter"
        @updateBranchesFilter="updateBranchesFilter($event)"
        @updateDateFilter="updateDateFilter($event)"
        @updateEmployeesFilter="updateEmployeesFilter($event)"
        @updateStatusFilter="updateStatusFilter($event)"
      ></filter-area>
    </div>
    <!-- End: Filter area -->
    <!-- Start: Main area -->
    <div class="flex-grow-1 overflow-hidden">
      <!-- Start: Top actions -->
      <top-action
        :current-page="currentPage"
        :selected-show-filter-id="itemPerPage"
        :search-key="searchKey"
        @exportReportFile="exportReportFile"
        @updateCurrentPage="updateCurrentPage($event)"
        @updateItemPerPage="updateItemPerPage($event)"
        @updateSearchKey="updateSearchKey($event)"
      ></top-action>
      <!-- End: Top actions -->
      <!-- Start: Data table -->
      <div class="mr-5">
        <data-table-items></data-table-items>
      </div>
      <!-- End: Data table -->
    </div>
    <!-- End: Main area -->
    <!-- Start: Modal 3rd store -->
    <!-- End: Modal 3rd store -->
    <modal-browsing-services
      :employees="employees"
      @updateList="handleUpdateList"
    />
  </div>
</template>

<script>
import DataTableItems from "./components/DataTableItems";
import FilterArea from "./components/FilterArea";
import TopAction from "./components/TopAction";
import ModalBrowsingServices from "./components/ModalForm";
import { exportExcelFile } from "@/core/composables";

export default {
  components: {
    DataTableItems,
    FilterArea,
    TopAction,
    ModalBrowsingServices
  },
  data() {
    return {
      branchesFilter: [],
      currentPage: 1,
      dateFilter: {
        value: null,
        type: 1
      },
      employeesFilter: [],
      itemPerPage: 50,
      searchKey: null,
      statusFilter: [],
      customerFilter: null,
      isLoading: false
    };
  },

  watch: {
    "$route.query"(val) {
      this.getSaleReceiptsFromRouteQuery(val);
    }
  },

  computed: {
    employees() {
      return this.$store.getters["EMPLOYEE/allEmployees"];
    }
  },

  async created() {
    const route = this.$route;

    await this.getSaleReceiptsFromRouteQuery(route.query);
    await this.$store.dispatch("EMPLOYEE/getAllEmployees");
  },

  methods: {
    async exportReportFile() {
      await exportExcelFile(this, {
        storeName: "BROWSING",
        payload: {
          branchIds: this.branchesFilter,
          status: this.statusFilter,
          employeeIds: this.employeesFilter,
          fromDate: this.dateFilter.value ? this.dateFilter.value[0] : null,
          toDate: this.dateFilter.value ? this.dateFilter.value[1] : null
        },
        fileName: "bao-cao-phuc-vu",
        isCheckExportTime: true
      });
    },

    async pushRouter() {
      await this.$router.push({
        name: "transaction_browsing-services",
        query: {
          branch_id:
            this.branchesFilter && this.branchesFilter.length > 0
              ? this.branchesFilter
              : undefined,
          created_by_id:
            this.employeesFilter && this.employeesFilter.length > 0
              ? this.employeesFilter
              : undefined,
          status:
            this.statusFilter && this.statusFilter !== "all"
              ? this.statusFilter
              : undefined,
          date_type:
            this.dateFilter.value &&
            this.dateFilter.value[0] &&
            this.dateFilter.type
              ? this.dateFilter.type
              : undefined,
          date_from:
            this.dateFilter && this.dateFilter.value
              ? this.dateFilter.value[0]
              : undefined,
          date_to:
            this.dateFilter && this.dateFilter.value
              ? this.dateFilter.value[1]
              : undefined,
          search:
            this.searchKey && this.searchKey.length > 0
              ? this.searchKey
              : undefined,
          limit: this.itemPerPage,
          page: this.currentPage
        }
      });
    },

    async getSaleReceiptsFromRouteQuery(query) {
      if (query.page) {
        // Set filtered branches
        this.branchesFilter =
          typeof query.branch_id === "string"
            ? [parseInt(query.branch_id)]
            : typeof query.branch_id === "object"
            ? query.branch_id.map(item => parseInt(item))
            : [];
        // Set current page
        this.currentPage = parseInt(query.page);
        // Set filtered date
        this.dateFilter = query.date_from
          ? {
              value: [query.date_from, query.date_to],
              type: parseInt(query.date_type)
            }
          : { value: null, type: 1 };
        // Set filtered branches
        this.employeesFilter =
          typeof query.created_by_id === "string"
            ? [parseInt(query.created_by_id)]
            : typeof query.created_by_id === "object"
            ? query.created_by_id.map(item => parseInt(item))
            : [];
        // Set item per page
        this.itemPerPage = parseInt(query.limit);
        // Set search key
        this.searchKey = query.search || null;
        // Set filtered status
        this.statusFilter =
          typeof query.status === "string"
            ? [parseInt(query.status)]
            : typeof query.status === "object"
            ? query.status.map(item => parseInt(item))
            : [];

        // Get products
        await this.$store.dispatch("BROWSING/getBrowsingServices", {
          filter: {
            branch_id: this.branchesFilter,
            user_id: this.employeesFilter,
            status: this.statusFilter,
            role: ["TECHNIQUE", "TECHNIQUE_HARDWARE", "PACKAGING"],
            time_from:
              this.dateFilter && this.dateFilter.value
                ? this.dateFilter.value[0]
                : null,
            time_to:
              this.dateFilter && this.dateFilter.value
                ? this.dateFilter.value[1]
                : null
          },
          search: this.searchKey,
          per_page: this.itemPerPage,
          cur_page: this.currentPage
        });
      } else {
        // Push router query
        await this.pushRouter();
      }
    },

    updateBranchesFilter(val) {
      this.branchesFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateCurrentPage(page) {
      if (this.currentPage === page) return;
      this.currentPage = page;
      this.selectedProductOptions = [];
      // Push router query
      this.pushRouter();
    },

    updateDateFilter(val) {
      this.dateFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateEmployeesFilter(val) {
      this.employeesFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateItemPerPage(num) {
      if (this.itemPerPage === num) return;
      this.itemPerPage = num;
      this.currentPage = 1;
      this.selectedProductOptions = [];
      // Push router query
      this.pushRouter();
    },

    updateStatusFilter(val) {
      this.statusFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateSearchKey(val) {
      this.searchKey = val.searchKey;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    async handleUpdateList() {
      await this.getSaleReceiptsFromRouteQuery(this.$route.query);
    }
  }
};
</script>

<style lang="scss" scoped></style>
