<template>
  <v-data-table
    :headers="headers"
    :items="browsingServices"
    :loading="
      browsingServicesStatusRequest.value === 'loading-getBrowsingServices'
    "
    calculate-widths
    class="tp-table-scroll tp-table__row-pointer datatable px-3 py-2"
    disable-pagination
    hide-default-footer
    item-key="id"
    loading-text="Đang tải dữ liệu"
    no-data-text="Không có dữ liệu"
    no-results-text="Không tìm thấy kết quả phù hợp"
    @click:row="openEdit($event)"
  >
    <template v-slot:[`item.role`]="{ item }">
      <span>
        {{ getNameRole(item.role) }}
      </span>
      <span v-if="item.role === 'SHIPPING' && item.value"
        >({{ item.value }} km)</span
      >
    </template>
    <template v-slot:[`item.user`]="{ item }">
      <span v-if="item.user && item.user.name">{{ item.user.name }}</span>
    </template>
    <template v-slot:[`item.model_code`]="{ item }">
      <span>
        <span class="mr-1">{{ item.model_code }}</span>
        <v-chip
          v-if="
            getValueModelStatus(item.model_status, listStatusBill).length > 0 &&
              item.model_name === 'App\\Models\\HoaDonBan'
          "
          :class="
            item.model_status === 1
              ? 'green lighten-5 green--text'
              : item.model_status === -1
              ? 'red lighten-5 red--text'
              : 'blue lighten-5 blue-text'
          "
          class="font-weight-bold px-1 ml-1"
          label
          small
        >
          {{ getValueModelStatus(item.model_status, listStatusBill) }}
        </v-chip>
        <v-chip
          v-if="
            getValueModelStatus(item.model_status, listStatusOrder).length >
              0 && item.model_name === 'App\\Models\\OrderHandle'
          "
          :class="
            item.model_status === 6
              ? 'green lighten-5 green--text'
              : item.model_status === -1
              ? 'red lighten-5 red--text'
              : 'blue lighten-5 blue--text'
          "
          class="font-weight-bold px-1 ml-1"
          label
          small
        >
          {{ getValueModelStatus(item.model_status, listStatusOrder) }}
        </v-chip>
        <v-chip
          v-if="
            getValueModelStatus(item.model_status, listStatusBHSC).length > 0 &&
              item.model_name === 'App\\Models\\BHSC'
          "
          :class="
            item.model_status === 1
              ? 'green lighten-5 green--text'
              : item.model_status === -1
              ? 'red lighten-5 red--text'
              : 'blue lighten-5 blue--text'
          "
          class="font-weight-bold px-1 ml-1"
          label
          small
        >
          {{ getValueModelStatus(item.model_status, listStatusBHSC) }}
        </v-chip>
        <v-chip
          v-if="
            getValueModelStatus(item.model_status, listStatusReturn).length >
              0 && item.model_name === 'App\\Models\\KhachTraHang'
          "
          :class="
            item.model_status === 1
              ? 'green lighten-5 green--text'
              : item.model_status === -1
              ? 'red lighten-5 red--text'
              : 'blue lighten-5 blue--text'
          "
          class="font-weight-bold"
          label
          small
        >
          {{ getValueModelStatus(item.model_status, listStatusReturn) }}
        </v-chip>
        <v-chip
          v-if="
            getValueModelStatus(item.model_status, listStatusChuyenHang)
              .length > 0 && item.model_name === 'App\\Models\\ChuyenHang'
          "
          :class="
            item.model_status === 1
              ? 'green lighten-5 green--text'
              : item.model_status === -1
              ? 'red lighten-5 red--text'
              : 'blue lighten-5 blue--text'
          "
          class="font-weight-bold"
          label
          small
        >
          {{ getValueModelStatus(item.model_status, listStatusChuyenHang) }}
        </v-chip>
      </span>
    </template>
    <template #[`item.docs`]="{ item }">
      <div v-if="item.docs" class="d-flex align-center">
        <div
          v-for="image in item.docs.slice(0, 2)"
          :key="image"
          class="img-wrapper mr-1"
        >
          <v-dialog max-width="80%">
            <template v-slot:activator="{ on, attrs }">
              <v-img
                :aspect-ratio="1"
                :src="image"
                class="grey darken-3 rounded"
                contain
                v-bind="attrs"
                v-on="on"
              ></v-img>
            </template>
            <div class="white pa-8" style="height: 70vh">
              <v-img
                :src="image"
                contain
                height="100%"
                max-height="70vh"
              ></v-img>
            </div>
          </v-dialog>
        </div>
        <div v-if="item.docs.length > 2" class="primary--text">+2</div>
      </div>
      <span v-else>-</span>
    </template>
    <template v-slot:[`item.status`]="{ item }" class="text-center">
      <v-chip
        :color="
          item.status === 1
            ? 'green'
            : item.status === 0
            ? 'amber'
            : 'red accent-2'
        "
        class="font-weight-bold"
        outlined
        small
      >
        {{
          item.status === 1
            ? "Đã duyệt"
            : item.status === 0
            ? "Chờ tài liệu"
            : "Bị từ chối"
        }}
      </v-chip>
    </template>
    <template v-slot:[`item.actions`]="{ item }" class="text-center">
      <v-btn
        v-if="item.status !== -1"
        class="red lighten-5 mx-3"
        color="red accent-2"
        icon
        small
        @click.stop="
          rejectBrowsing(
            item.id,
            browsingServices
              .map(function(x) {
                return x.id;
              })
              .indexOf(item.id)
          )
        "
      >
        <v-icon size="18px">mdi-close-outline</v-icon>
      </v-btn>

      <v-btn
        v-if="item.status === 0"
        class="green lighten-5"
        color="green"
        icon
        small
        @click.stop="
          approveBrowsing(
            item.id,
            browsingServices
              .map(function(x) {
                return x.id;
              })
              .indexOf(item.id)
          )
        "
      >
        <v-icon size="18px">mdi-check-outline</v-icon>
      </v-btn>
    </template>
    <!-- End: Body -->
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id"
        },
        {
          text: "Thời gian",
          align: "start",
          sortable: false,
          value: "created_at"
        },
        {
          text: "Nhân sự",
          align: "start",
          sortable: false,
          value: "user"
        },
        {
          text: "Role phục vụ",
          align: "start",
          sortable: false,
          value: "role"
        },
        {
          text: "Chi tiết",
          align: "start",
          sortable: false,
          value: "note"
        },
        {
          text: "Chứng từ",
          align: "start",
          sortable: false,
          value: "model_code"
        },
        {
          text: "Tài liệu",
          align: "start",
          sortable: false,
          value: "docs"
        },
        {
          text: "Trạng thái duyệt",
          align: "start",
          sortable: false,
          value: "status"
        },
        {
          text: "Thao tác",
          align: "center",
          sortable: false,
          value: "actions"
        }
      ],
      listStatusOrder: [
        {
          id: -2,
          value: "Chờ xác nhận"
        },
        {
          id: -1,
          value: "Đã hủy"
        },
        {
          id: 1,
          value: "Đã duyệt"
        },
        {
          id: 2,
          value: "Chờ duyệt"
        },
        {
          id: 3,
          value: "Đã sẵn sàng"
        },
        {
          id: 4,
          value: "Đang chuẩn bị"
        },
        {
          id: 5,
          value: "Đang vận chuyển"
        },
        {
          id: 6,
          value: "Đã hoàn thành"
        }
      ],
      listStatusBill: [
        {
          id: -1,
          value: "Đã hủy"
        },
        {
          id: 1,
          value: "Thành công"
        }
      ],
      listStatusReturn: [
        {
          id: -1,
          value: "Đã hủy"
        },
        {
          id: 1,
          value: "Đã trả"
        }
      ],
      listStatusBHSC: [
        {
          id: -1,
          value: "Đã hủy"
        },
        {
          id: 1,
          value: "Đã tiếp nhận"
        },
        {
          id: 2,
          value: "Đang xử lý"
        },
        {
          id: 3,
          value: "Xử lý xong"
        },
        {
          id: 4,
          value: "Đã trả khách"
        }
      ],
      listStatusChuyenHang: [
        {
          id: -2,
          value: "Chờ xác nhận"
        },
        {
          id: -1,
          value: "Đã hủy"
        },
        {
          id: 1,
          value: "Đã chuyển"
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      browsingServices: "BROWSING/browsingServices",
      browsingServicesStatusRequest: "BROWSING/statusRequest",
      roles: "ROLE/rolesName"
    })
  },
  methods: {
    async rejectBrowsing(id, index) {
      await this.$store.dispatch("BROWSING/rejectBrowsingService", {
        id,
        index
      });
      // Alert and replace router
      if (
        this.browsingServicesStatusRequest.value ===
        "success-rejectBrowsingService"
      ) {
        // Alert
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Từ chối thành công"
          }
        });
      }
      // Alert and replace router
      if (
        this.browsingServicesStatusRequest.value ===
        "error-rejectBrowsingService"
      ) {
        // Alert
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: this.browsingServicesStatusRequest.message
              ? this.browsingServicesStatusRequest.message
              : ""
          }
        });
      }
    },
    async approveBrowsing(id, index) {
      await this.$store.dispatch("BROWSING/approveBrowsingService", {
        id,
        index
      });
      // Alert and replace router
      if (
        this.browsingServicesStatusRequest.value ===
        "success-approveBrowsingService"
      ) {
        // Alert
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã duyệt thành công"
          }
        });
      }
      // Alert and replace router
      if (
        this.browsingServicesStatusRequest.value ===
        "error-approveBrowsingService"
      ) {
        // Alert
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: this.browsingServicesStatusRequest.message
              ? this.browsingServicesStatusRequest.message
              : ""
          }
        });
      }
    },
    async openEdit(item) {
      await this.$store.dispatch("BROWSING/getBrowsingService", item.id);
      this.$modal.show({ name: "modal-browsing-services" });
    },
    getNameRole(role) {
      if (role === "PACKAGING") return "Đóng gói";
      else if (this.roles.filter(item => item.value === role).length > 0)
        return this.roles.filter(item => item.value === role)[0]["text"];
      return "N/A";
    },
    getValueModelStatus(value, listStatus) {
      let text = listStatus.filter(item => item.id === value);
      if (text.length > 0) return text[0].value;
      else return "";
    }
  }
};
</script>

<style lang="scss" scoped>
.datatable {
  .v-data-table-header {
    background-color: green;
  }

  .copied-label {
    cursor: pointer;
    display: inline-block;
  }

  .view-label {
    cursor: pointer;
    transition: all 0.4s ease;
  }
}

.img-wrapper {
  cursor: pointer;
  height: 32px;
  width: 32px;
}
</style>
