<template>
  <div class="filter-area">
    <div class="font-weight-bold text-h5 mb-4">Duyệt phục vụ</div>
    <v-card class="tp-filter-scroll pa-5" flat>
      <!-- Start: Branches -->
      <div class="mt-0">
        <div class="font-weight-bold mb-2">Chi nhánh</div>
        <v-autocomplete
          v-model="selectedBranches"
          class="tp-filter-autocomplete"
          :items="branches"
          dense
          deletable-chips
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
          no-data-text="Không có dữ liệu"
          outlined
          chips
          single-line
          small-chips
          label="Outlined"
          multiple
          placeholder="Chọn chi nhánh"
        ></v-autocomplete>
      </div>
      <!-- Start: Employees -->
      <div class="mt-5">
        <div class="font-weight-bold mb-2">Nhân sự</div>
        <v-autocomplete
          v-model="selectedEmployees"
          class="tp-filter-autocomplete"
          :items="employees"
          dense
          deletable-chips
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
          no-data-text="Không có dữ liệu"
          outlined
          chips
          single-line
          small-chips
          label="Outlined"
          multiple
          placeholder="Chọn nhân sự"
        ></v-autocomplete>
      </div>
      <!-- Start: Status -->
      <div class="mt-5">
        <div class="font-weight-bold mb-2">Trạng thái</div>
        <v-checkbox
          v-model="selectedStatus"
          class="mt-1"
          dense
          hide-details
          v-for="item in status"
          :label="item.text"
          :key="item.id"
          :value="item.id"
        ></v-checkbox>
      </div>
      <!-- Start: Time -->
      <div class="mt-5">
        <div class="font-weight-bold mb-2">Thời gian</div>
        <tp-input-time-filter
          :type="dateFilter.type"
          :value="dateFilter.value"
          @change="updateDateFilter($event)"
        ></tp-input-time-filter>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    employees: {
      type: Array
    },
    branchesFilter: {
      type: Array
    },
    dateFilter: {
      type: Object
    },
    employeesFilter: {
      type: Array
    },
    statusFilter: {
      type: Array,
      default: null
    },
    customerFilter: {
      type: [Number, String],
      default: null
    }
  },
  computed: {
    branches() {
      return this.$store.getters["BRANCH/allBranches"];
    },
    selectedBranches: {
      get() {
        return this.branchesFilter;
      },
      set(val) {
        this.$emit("updateBranchesFilter", val);
      }
    },
    selectedEmployees: {
      get() {
        return this.employeesFilter;
      },
      set(val) {
        this.$emit("updateEmployeesFilter", val);
      }
    },
    selectedStatus: {
      get() {
        return this.statusFilter;
      },
      set(val) {
        this.$emit("updateStatusFilter", val);
      }
    },
    selectedCustomer: {
      get() {
        return this.customerFilter;
      },
      set() {
        // this.$emit("updateStatusFilter", val);
      }
    }
  },
  data() {
    return {
      status: [
        {
          id: 1,
          text: "Đã duyệt"
        },
        {
          id: 0,
          text: "Chờ tài liệu"
        },
        {
          id: -1,
          text: "Bị từ chối"
        }
      ]
    };
  },
  created() {
    this.$store.dispatch("BRANCH/getAllBranches");
  },
  methods: {
    updateDateFilter(val) {
      this.$emit("updateDateFilter", val);
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-area {
  width: 256px;
  .tp-filter-scroll {
    overflow-y: auto;
  }
}
</style>
