var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"tp-table-scroll tp-table__row-pointer datatable px-3 py-2",attrs:{"headers":_vm.headers,"items":_vm.browsingServices,"loading":_vm.browsingServicesStatusRequest.value === 'loading-getBrowsingServices',"calculate-widths":"","disable-pagination":"","hide-default-footer":"","item-key":"id","loading-text":"Đang tải dữ liệu","no-data-text":"Không có dữ liệu","no-results-text":"Không tìm thấy kết quả phù hợp"},on:{"click:row":function($event){return _vm.openEdit($event)}},scopedSlots:_vm._u([{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getNameRole(item.role))+" ")]),(item.role === 'SHIPPING' && item.value)?_c('span',[_vm._v("("+_vm._s(item.value)+" km)")]):_vm._e()]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [(item.user && item.user.name)?_c('span',[_vm._v(_vm._s(item.user.name))]):_vm._e()]}},{key:"item.model_code",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(item.model_code))]),(
          _vm.getValueModelStatus(item.model_status, _vm.listStatusBill).length > 0 &&
            item.model_name === 'App\\Models\\HoaDonBan'
        )?_c('v-chip',{staticClass:"font-weight-bold px-1 ml-1",class:item.model_status === 1
            ? 'green lighten-5 green--text'
            : item.model_status === -1
            ? 'red lighten-5 red--text'
            : 'blue lighten-5 blue-text',attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(_vm.getValueModelStatus(item.model_status, _vm.listStatusBill))+" ")]):_vm._e(),(
          _vm.getValueModelStatus(item.model_status, _vm.listStatusOrder).length >
            0 && item.model_name === 'App\\Models\\OrderHandle'
        )?_c('v-chip',{staticClass:"font-weight-bold px-1 ml-1",class:item.model_status === 6
            ? 'green lighten-5 green--text'
            : item.model_status === -1
            ? 'red lighten-5 red--text'
            : 'blue lighten-5 blue--text',attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(_vm.getValueModelStatus(item.model_status, _vm.listStatusOrder))+" ")]):_vm._e(),(
          _vm.getValueModelStatus(item.model_status, _vm.listStatusBHSC).length > 0 &&
            item.model_name === 'App\\Models\\BHSC'
        )?_c('v-chip',{staticClass:"font-weight-bold px-1 ml-1",class:item.model_status === 1
            ? 'green lighten-5 green--text'
            : item.model_status === -1
            ? 'red lighten-5 red--text'
            : 'blue lighten-5 blue--text',attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(_vm.getValueModelStatus(item.model_status, _vm.listStatusBHSC))+" ")]):_vm._e(),(
          _vm.getValueModelStatus(item.model_status, _vm.listStatusReturn).length >
            0 && item.model_name === 'App\\Models\\KhachTraHang'
        )?_c('v-chip',{staticClass:"font-weight-bold",class:item.model_status === 1
            ? 'green lighten-5 green--text'
            : item.model_status === -1
            ? 'red lighten-5 red--text'
            : 'blue lighten-5 blue--text',attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(_vm.getValueModelStatus(item.model_status, _vm.listStatusReturn))+" ")]):_vm._e(),(
          _vm.getValueModelStatus(item.model_status, _vm.listStatusChuyenHang)
            .length > 0 && item.model_name === 'App\\Models\\ChuyenHang'
        )?_c('v-chip',{staticClass:"font-weight-bold",class:item.model_status === 1
            ? 'green lighten-5 green--text'
            : item.model_status === -1
            ? 'red lighten-5 red--text'
            : 'blue lighten-5 blue--text',attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(_vm.getValueModelStatus(item.model_status, _vm.listStatusChuyenHang))+" ")]):_vm._e()],1)]}},{key:"item.docs",fn:function(ref){
            var item = ref.item;
return [(item.docs)?_c('div',{staticClass:"d-flex align-center"},[_vm._l((item.docs.slice(0, 2)),function(image){return _c('div',{key:image,staticClass:"img-wrapper mr-1"},[_c('v-dialog',{attrs:{"max-width":"80%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"grey darken-3 rounded",attrs:{"aspect-ratio":1,"src":image,"contain":""}},'v-img',attrs,false),on))]}}],null,true)},[_c('div',{staticClass:"white pa-8",staticStyle:{"height":"70vh"}},[_c('v-img',{attrs:{"src":image,"contain":"","height":"100%","max-height":"70vh"}})],1)])],1)}),(item.docs.length > 2)?_c('div',{staticClass:"primary--text"},[_vm._v("+2")]):_vm._e()],2):_c('span',[_vm._v("-")])]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":item.status === 1
          ? 'green'
          : item.status === 0
          ? 'amber'
          : 'red accent-2',"outlined":"","small":""}},[_vm._v(" "+_vm._s(item.status === 1 ? "Đã duyệt" : item.status === 0 ? "Chờ tài liệu" : "Bị từ chối")+" ")])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [(item.status !== -1)?_c('v-btn',{staticClass:"red lighten-5 mx-3",attrs:{"color":"red accent-2","icon":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.rejectBrowsing(
          item.id,
          _vm.browsingServices
            .map(function(x) {
              return x.id;
            })
            .indexOf(item.id)
        )}}},[_c('v-icon',{attrs:{"size":"18px"}},[_vm._v("mdi-close-outline")])],1):_vm._e(),(item.status === 0)?_c('v-btn',{staticClass:"green lighten-5",attrs:{"color":"green","icon":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.approveBrowsing(
          item.id,
          _vm.browsingServices
            .map(function(x) {
              return x.id;
            })
            .indexOf(item.id)
        )}}},[_c('v-icon',{attrs:{"size":"18px"}},[_vm._v("mdi-check-outline")])],1):_vm._e()]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }